import riRootStoreInstance, { RiRootStore } from "../Store/RiRootStore"

export class RiLinkUtils {
    public static contactRek(): void {
        const store: RiRootStore = riRootStoreInstance;
        store.RiSlideoutStore.updateStateAsync({ isReachOutShowing: true });
    }

    public static launchEmail(): void {
        window.open("mailto:hello@rekit.app", "_blank");
    }
}