import { RiPeople } from '../Icons/RiPeople/RiPeople';
import './RiRekRow.scss';
import '../../GlobalStyles/iconStyles.scss';
import { RiThumbUp } from '../Icons/RiThumbUp/RiThumbUp';
import { RiComment } from '../Icons/RiComment/RiComment';
import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';

export interface IRiRekRowProps {
    title: string;
    description: string;
    category: string;
    image: string;
    favicon: string;
    reks: number;
    likes: number;
    comments: number;
}

export const RiRekRow = (props: IRiRekRowProps): JSX.Element => {
    const store: RiRootStore = riRootStoreInstance;
    return (
        <div className='rek-row-container'>

            {/* LINK IMAGE */}
            <img
                className='rek-row-image'
                src={props.image}
                onLoad={() => store.RiLoadingStore.imgLoaded()}
            />

            {/* SECOND COLUMN CONTAINER */}
            <div className='rek-row-second-column '>


                {/* TITLE AND FAV */}
                <div className='rek-row-title-fav-container'>
                    <img
                        className='rek-row-favicon'
                        src={props.favicon}
                        onLoad={() => store.RiLoadingStore.imgLoaded()}
                    />

                    {/* TITLE */}
                    <span className='rek-row-title'>{props.title}</span>
                </div>

                {/* DESCRIPTION */}
                <span className='rek-row-description'>{props.description}</span>

                {/* ICONS */}
                <div className='rek-icons'>

                    {/* PEOPLE */}
                    <RiPeople className='path-fill-grey' />
                    <span className='rek-icon-text'>{props.reks}</span>

                    {/* THUMB UP */}
                    <RiThumbUp className='path-fill-grey' />
                    <span className='rek-icon-text'>{props.likes}</span>

                    {/* COMMENTS */}
                    <RiComment className='path-fill-grey' />
                    <span className='rek-icon-text'>{props.comments}</span>

                    {/* CATEGORY */}
                    <span style={{ marginLeft: 0 }} className='rek-icon-text'>{props.category}</span>


                </div>

            </div>

        </div>
    )
}