import { RiLink } from '../../Components/Icons/RiLink/RiLink';
import { RiRekLogo } from '../../Components/Icons/RiRekLogo/RiRekLogo';
import { RiSend } from '../../Components/Icons/RiSend/RiSend';
import { RiBullet } from '../../Components/RiBullet/RiBullet';
import ssWordle from '../../Assets/Screenshots/Feb2022/wordleSlide.png';
import './RiHowWork.scss';
import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';

export const RiHowWork = (): JSX.Element => {
    const store: RiRootStore = riRootStoreInstance;
    return (
        <>
            <div className='main-view-container how-work-bottom-spacing'>

                {/* LEFT SIDE */}
                <div className='column-container how-work-ani-list'>
                    {/* HEADLINE */}
                    {/* <h1><strong>How does it work?</strong></h1> */}
                    <p className='how-work-ani-header'><strong>How does it work?</strong></p>

                    {/* BULLETS */}
                    <div className='ani-bullets'>
                        <RiBullet
                            icon={<RiLink className='path-fill-white' />}
                            text={<span>Grab a link.</span>}
                        />

                        <RiBullet
                            icon={<RiSend className='path-fill-white' style={{ marginRight: 2 }} />}
                            text={<span>Send it to a friend.</span>}
                        />

                        <RiBullet
                            icon={<RiRekLogo className='path-fill-white' />}
                            text={
                                <span>They can <span className='brand-fg'>like</span>, <span className='brand-fg'>dislike</span>, <span className='brand-fg'>comment</span>, or add the rek to their <span className='brand-fg'>list.</span></span>
                            }
                        />
                    </div>
                </div>

                {/* APP IMAGE */}
                <div className='how-work-screenshot'>
                    <img
                        src={ssWordle}
                        className='how-work-screenshot-image'
                        onLoad={() => store.RiLoadingStore.imgLoaded()}
                    />
                </div>

            </div>
        </>
    )
}