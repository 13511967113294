import { makeAutoObservable } from "mobx";
import { RiRootStore } from "../RiRootStore";

export interface IRiStore {
    rootStore: RiRootStore;
}

export class RiStoreUtils {
    // Makes state properties observable by mobX and classifies methods as actions.
    public static initialize<T extends IRiStore>(ctx: T, rootStore: RiRootStore): void {
        ctx.rootStore = rootStore
        makeAutoObservable(ctx, { rootStore: false });
    }
    // Basic update state method that sets properties to the new state value.
    public static async updateStateAsync<T extends IRiStore>(ctx: T, newState: Partial<T>): Promise<void> {
        return new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) => {
            try {
                Object.assign(ctx, { ...newState });
                resolve();
            } catch (error) {
                reject();
            }
        });

    }
}