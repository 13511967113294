import React from 'react';
import ReactDOM from 'react-dom';
import './GlobalStyles/miscStyles.scss';
import './GlobalStyles/layoutStyles.scss';
import { RiAppView } from './Views/RiApp/RiAppView';


ReactDOM.render(
  <React.StrictMode>
    <RiAppView />
  </React.StrictMode>,
  document.getElementById('root')
);
