export class RiStringUtils {

    //#region App Download Links
    public static iosDownload: string = '';
    public static androidDownload: string = '';
    //#endregion

    //#region Tag Link Text
    public static btnDownload: string = '🤙 Download'
    public static btnDownloadFloater: string = 'Download'
    public static btnContact: string = '👋 Reach Out'
    //#endregion

    //#region Icon Links
    public static iconLinkAndroid: string = 'Android';
    public static iconLinkiOS: string = 'iOS';
    public static iconLinkCopy: string = 'Copy Email';
    public static iconLinkCopied: string = 'Copied!';
    public static iconLinkOpen: string = 'Open';
    //#endregion
}