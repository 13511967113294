import './RiBlurButton.scss';

export interface IRiBlurButtonProps {
    text: string;
    icon?: JSX.Element;
    handler: () => void;
}

export const RiBlurButton = (props: IRiBlurButtonProps): JSX.Element => {
    return (
        <div
            className="download-button-container"
            onClick={props.handler}
        >

            {/* ICON */}
            {props.icon && props.icon}

            {/* TEXT */}
            <p style={!props.icon ? { margin: 0 } : undefined} className="download-text">{props.text}</p>

        </div>
    )
}