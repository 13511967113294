import './RiBullet.scss';

export interface IRiBullet {
    icon: JSX.Element;
    text: JSX.Element;
}

export const RiBullet = (props: IRiBullet): JSX.Element => {
    return (
        <div className='bullet-list-container'>

            {/* ICON */}
            <div className='bullet-icon-container'>
                {props.icon}
            </div>

            {/* BULLET TEXT */}
            <div className='bullet-text'>
                {props.text}
            </div>
        </div>
    )
}