import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';
import './RiLinkTag.scss';

export interface IRiLinkTagProps {
    text: string;
    handler: () => void;
    isSticky?: boolean;
}

export const RiLinkTag = (props: IRiLinkTagProps): JSX.Element => {
    const store: RiRootStore = riRootStoreInstance;
    return (
        <div className={`${props.isSticky && 'link-tag-floating'} link-tag-container ${store.RiLoadingStore.isAniFirstPaint && 'viewport-animation-y-rev'}`} onClick={props.handler}>
            {props.text}
        </div>
    )
}