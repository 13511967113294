import { IRiRekRowProps, RiRekRow } from '../../Components/RiRekRow/RiRekRow';
import { rekExamples } from './rekExamples';
import './RiWhatsRekView.scss';

export const RiWhatsRek = (): JSX.Element => {

    //#region Categories
    const categoryArray: string[] = ['Movies', 'TV Shows', 'Books', 'Music', 'etc.']
    //#endregion

    return (
        <>
            <div className='main-view-container whats-rek-bottom-spacing'>

                {/* HEADLINE */}
                <div className='column-container'>

                    {/* H1 */}
                    {/* <h1><strong>What's Rek?</strong></h1> */}

                    {/* TAGLINE */}
                    <p className='tagline-text'><span className='brand-fg'>Send</span> and <span className='brand-fg'>receive</span> recommendations between friends.</p>

                    {/* CATEGORIES */}
                    <div className='category-container'>
                        {categoryArray.map((category: string, idx: number) => {
                            return (
                                <p key={idx} className={`category-tag ${((idx === 1) || (idx === 2)) && 'category-tag-light'}`}>{category}</p>
                            );
                        })}
                    </div>
                </div>

                {/* REK ROW EXAMPLES */}
                <div className='whats-rek-examples'>
                    {rekExamples.map((rek: IRiRekRowProps, idx: number) => {
                        return (
                            <RiRekRow
                                key={idx}
                                {...rek}
                            />
                        )
                    })}
                </div>

            </div>


        </>
    )
}