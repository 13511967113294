import { RiRootStore } from "./RiRootStore";
import { IRiStore, RiStoreUtils } from "./Utils/RiStoreUtils";

export class RiLoadingStore implements IRiStore {
    rootStore!: RiRootStore;

    constructor(rootStore: RiRootStore) {
        RiStoreUtils.initialize(this, rootStore);
    }

    //#region Properties
    public get isPageLoaded(): boolean {
        return this.imagesToLoad === 0;
    }
    public imagesToLoad: number = 11;
    public isAniFirstPaint: boolean = false;
    //#endregion

    //#region Methods
    public async updateStateAsync(newState: Partial<RiLoadingStore>): Promise<void> {
        RiStoreUtils.updateStateAsync(this, newState);
    }

    public async imgLoaded(): Promise<void> {
        await this.updateStateAsync({ imagesToLoad: this.imagesToLoad - 1 });
    }
    //#endregion
}