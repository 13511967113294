import { RiPageLoader } from '../../Components/RiPageLoader/RiPageLoader';
import { RiViewportAniUtils } from '../../Utils/RiViewportAniUtils';
import { RiLandingView } from '../RiLandingView/RiLandingView';
import './RiAppView.scss';

export const RiAppView = (): JSX.Element => {
  // Adds observer for scroll into view animations
  RiViewportAniUtils.initObserver();

  //#region Render
  return (
    // App Container
    <div className='app-container'>

      {/* PAGE LOADER */}
      <RiPageLoader />

      {/* LANDING PAGE */}
      <RiLandingView />

    </div>
  )

  //#endregion
}
