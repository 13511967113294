export interface IRiViewportAni {
  element: Element;
  type: "y" | "x" | "y-rev" | "x-rev";
}

export class RiViewportAniUtils {
  //#region Animation Observer
  public static initObserver(): void {
    setTimeout(() => {
      //#region All divs we want to observe / animate
      const allDivsToAnimate: IRiViewportAni[] = [
        {
          element: document.querySelector(".tagline-text")!,
          type: "x",
        },
        {
          element: document.querySelector(".category-container")!,
          type: "y",
        },
        {
          element: document.querySelector(".whats-rek-examples")!,
          type: "x-rev",
        },
        {
          element: document.querySelector(".how-work-screenshot-image")!,
          type: "x-rev",
        },
        {
          element: document.querySelector(".how-work-ani-header")!,
          type: "x",
        },
        {
          element: document.querySelector(".how-work-ani-list")!,
          type: "y",
        },
        {
          element: document.querySelector(".ani-bullets")!,
          type: "y",
        },
        {
          element: document.querySelector(".why-header-ani")!,
          type: "x",
        },
        {
          element: document.querySelector(".why-action-icons")!,
          type: "x-rev",
        },
        {
          element: document.querySelector(".why-screenshot-image")!,
          type: "x-rev",
        },
        {
          element: document.querySelector(".thank-you-footer")!,
          type: "x",
        },
        {
          element: document.querySelector(".footer-copyright")!,
          type: "y",
        },
      ];
      //#endregion

      // Create the observer
      const observer: IntersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting && entry.intersectionRatio > 0) {
              // Add the animation class if it's not there.
              const targetClass: string = entry.target.className;
              // Find index of div in our initial array and use it's 'y' or 'x' value to determine which class to apply and therefore which animation to trigger
              const idxOfTarget: number = allDivsToAnimate.findIndex(
                (div: IRiViewportAni) => div.element.className === targetClass
              );
              let classToAdd: string = `viewport-animation-${allDivsToAnimate[idxOfTarget].type}`;
              !entry.target.classList.contains(classToAdd) &&
                entry.target.classList.add(classToAdd);
              observer.unobserve(entry.target);
              return;
            }
            // entry.target.classList.remove('viewport-animation-y');
          });
        },
        { threshold: 0.1 }
      );

      // Stuff to Observe
      allDivsToAnimate.forEach((specificDiv: IRiViewportAni) => {
        observer.observe(specificDiv.element);
      });
    }, 100);
  }
  //#endregion
}
