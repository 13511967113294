import { makeAutoObservable } from "mobx";
import { RiLoadingStore } from "./RiLoadingStore";
import { RiSlideoutStore } from "./RiSlideOutStore";

export class RiRootStore {
    constructor() {
        makeAutoObservable(this);
        this.RiLoadingStore = new RiLoadingStore(this);
        this.RiSlideoutStore = new RiSlideoutStore(this);
    }
    public RiLoadingStore: RiLoadingStore;
    public RiSlideoutStore: RiSlideoutStore;
}

const riRootStoreInstance = new RiRootStore();
export default riRootStoreInstance;