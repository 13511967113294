import { observer } from "mobx-react-lite";
import { useState } from "react";
import { RiRekLogo } from "../../Components/Icons/RiRekLogo/RiRekLogo";
import { RiSlideType, RiSlideUpOut } from "../../Components/RiSlideUpOut/RiSlideUpOut";
import { RiLinkTag } from "../../Components/RiLinkTag/RiLinkTag";
import riRootStoreInstance, { RiRootStore } from "../../Store/RiRootStore";
import { RiStringUtils } from "../../Utils/RiStringUtils";
import { RiAboveFold } from "../RiAboveFold/RiAboveFold";
import { RiFooter } from "../RiFooter/RiFooter";
import { RiHowWork } from "../RiHowWork/RiHowWork";
import { RiWhatsRek } from "../RiWhatsRek/RiWhatsRekView";
import { RiWhy } from "../RiWhy/RiWhy";
import '../../GlobalStyles/animationStyles.scss';

export const RiLandingView = observer(() => {
    {
        const store: RiRootStore = riRootStoreInstance
        return (
            <>

                {/* DOWNLOAD MODAL */}
                {store.RiSlideoutStore.isDownloadModalShowing &&
                    <RiSlideUpOut
                        handleClose={() => store.RiSlideoutStore.updateStateAsync({ isDownloadModalShowing: false })}
                        type={RiSlideType.DownloadLinks}
                    />
                }

                {/* PRIVACY POLICY */}
                {store.RiSlideoutStore.isPrivacyShowing &&
                    <RiSlideUpOut
                        handleClose={() => store.RiSlideoutStore.updateStateAsync({ isPrivacyShowing: false })}
                        type={RiSlideType.PrivacyPolicy}
                    />
                }

                {/* REACH OUT*/}
                {store.RiSlideoutStore.isReachOutShowing &&
                    <RiSlideUpOut
                        handleClose={() => store.RiSlideoutStore.updateStateAsync({ isReachOutShowing: false })}
                        type={RiSlideType.ReachOut}
                    />
                }

                {/* LOGO */}
                <RiRekLogo className={`path-fill-brand app-logo ${store.RiLoadingStore.isAniFirstPaint && 'ani-scale-up'}`} />


                {/* DOWNLOAD FIXED */}
                <RiLinkTag
                    isSticky
                    text={RiStringUtils.btnDownloadFloater}
                    handler={() => store.RiSlideoutStore.updateStateAsync({ isDownloadModalShowing: true })}
                />

                {/* LANDING SECTION */}
                <RiAboveFold />

                {/* WHATS REK SECTION */}
                <RiWhatsRek />

                {/* HOW DOES IT WORK */}
                <RiHowWork />

                {/* WHY */}
                <RiWhy />

                {/* FOOTER */}
                <RiFooter
                    handleClickDownload={() => store.RiSlideoutStore.updateStateAsync({ isDownloadModalShowing: true })}
                    handleClickPrivacy={() => store.RiSlideoutStore.updateStateAsync({ isPrivacyShowing: true })}
                />

            </>
        )
    }
})