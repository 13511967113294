import { runInAction } from "mobx";
import { RiRootStore } from "./RiRootStore";
import { IRiStore, RiStoreUtils } from "./Utils/RiStoreUtils";

export class RiSlideoutStore implements IRiStore {
    rootStore!: RiRootStore;

    constructor(rootStore: RiRootStore) {
        RiStoreUtils.initialize(this, rootStore);
    }

    //#region Properties
    public isReachOutShowing: boolean = false;
    public isDownloadModalShowing: boolean = false;
    public isPrivacyShowing: boolean = false;
    public isLinkCopied: boolean = false;
    //#endregion

    //#region Methods
    public async updateStateAsync(newState: Partial<RiSlideoutStore>): Promise<void> {
        // Close all slideups first
        this.isReachOutShowing = false;
        this.isDownloadModalShowing = false;
        this.isPrivacyShowing = false;
        // Update state
        RiStoreUtils.updateStateAsync(this, newState);
    }

    public setLinkCopied(): void {
        this.isLinkCopied = true;
        setTimeout(() => {
            runInAction(() => {
                this.isLinkCopied = false;
            });
        }, 4000);
    }

    //#endregion
}    