import { observer } from "mobx-react-lite";
import { useState } from "react";
import riRootStoreInstance, { RiRootStore } from "../../Store/RiRootStore";
import { RiLinkUtils } from "../../Utils/RiLinkUtils";
import { RiStringUtils } from "../../Utils/RiStringUtils";
import { RiClose } from "../Icons/RiClose/RiClose";
import { RiCopy } from "../Icons/RiCopy/RiCopy";
import { RiEmail } from "../Icons/RiEmail/RiEmail";
import { RiBlurButton } from "../RiBlurButton/RiBlurButton";
import "./RiSlideUpOut.scss";

export enum RiSlideType {
  DownloadLinks,
  PrivacyPolicy,
  ReachOut,
}

export interface IRiDownloadLinksProps {
  handleClose: () => void;
  type: RiSlideType;
}

export const RiSlideUpOut = observer(
  (props: IRiDownloadLinksProps): JSX.Element => {
    const [isMount, setIsMount] = useState<boolean>(true);

    const store: RiRootStore = riRootStoreInstance;

    const handeClose = (): void => {
      setIsMount(false);
      setTimeout(() => {
        props.handleClose();
      }, 300);
    };

    const getRender = (): JSX.Element => {
      switch (props.type) {
        //#region DOWNLOAD LINKS
        case RiSlideType.DownloadLinks:
          return (
            <>
              {/* HEADLINE - DOWNLOAD READY */}
              <h2>
                <strong>🚧 Rek Decommission</strong>
              </h2>

              {/* PARAGRAPH - DOWNLOAD READY */}
              <p className="download-paragraph">
                After about 4 years, I decided it was time to take down rek. It
                was a fun project and I learned a ton but it's time to move on
                to other things!
              </p>

              <div className="download-multi-button-container">
                <RiBlurButton
                  text="Check out my portfolio!"
                  handler={() =>
                    window.open("https://jeffdayton.app/", "_blank")
                  }
                />
              </div>

              {/* DOWNLOAD BUTTONS */}
            </>
          );
        //#endregion

        //#region PRIVACY POLICY
        case RiSlideType.PrivacyPolicy:
          return (
            <>
              {/* HEADLINE - LAUNCH DATE COMING */}
              <h2>
                <strong>🔒&nbsp;&nbsp;Privacy Policy</strong>
              </h2>

              <p className="privacy-policy-text">
                Rek it. operates the https://rekit.app/ website, which provides
                the SERVICE.
              </p>

              <p className="privacy-policy-text">
                This page is used to inform website visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service, the Rek it.
                website.
              </p>

              <p className="privacy-policy-text">
                If you choose to use our Service, then you agree to the
                collection and use of information in relation with this policy.
                The Personal Information that we collect are used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy. Our Privacy Policy was created with the help of the{" "}
                <a href="https://www.privacypolicytemplate.net">
                  Privacy Policy Template Generator
                </a>
                .
              </p>

              <p className="privacy-policy-text">
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at
                https://rekit.app/, unless otherwise defined in this Privacy
                Policy.
              </p>

              <h3>Information Collection and Use</h3>

              <p className="privacy-policy-text">
                For a better experience while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to your name, phone
                number, and postal address. The information that we collect will
                be used to contact or identify you.
              </p>

              <h3>Log Data</h3>

              <p className="privacy-policy-text">
                We want to inform you that whenever you visit our Service, we
                collect information that your browser sends to us that is called
                Log Data. This Log Data may include information such as your
                computer’s Internet Protocol ("IP") address, browser version,
                pages of our Service that you visit, the time and date of your
                visit, the time spent on those pages, and other statistics.
              </p>

              <h3>Cookies</h3>

              <p className="privacy-policy-text">
                Cookies are files with small amount of data that is commonly
                used an anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                computer’s hard drive.
              </p>

              <p className="privacy-policy-text">
                Our website uses these "cookies" to collection information and
                to improve our Service. You have the option to either accept or
                refuse these cookies, and know when a cookie is being sent to
                your computer. If you choose to refuse our cookies, you may not
                be able to use some portions of our Service.
              </p>

              <p className="privacy-policy-text">
                For more general information on cookies, please read{" "}
                <a href="https://www.generateprivacypolicy.com/#cookies">
                  "Cookies" article from the Privacy Policy Generator
                </a>
                .
              </p>

              <h3>Service Providers</h3>

              <p className="privacy-policy-text">
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>

              <ul className="privacy-policy-text">
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>

              <p className="privacy-policy-text">
                We want to inform our Service users that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>

              <h3>Security</h3>

              <p className="privacy-policy-text">
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>

              <h3>Links to Other Sites</h3>

              <p className="privacy-policy-text">
                Our Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over, and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>

              <p className="privacy-policy-text">Children's Privacy</p>

              <p className="privacy-policy-text">
                Our Services do not address anyone under the age of 13. We do
                not knowingly collect personal identifiable information from
                children under 13. In the case we discover that a child under 13
                has provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal
                information, please{" "}
                <a
                  onClick={() => {
                    RiLinkUtils.contactRek();
                  }}
                >
                  contact us
                </a>{" "}
                so that we will be able to do necessary actions.
              </p>

              <h3>Changes to This Privacy Policy</h3>

              <p className="privacy-policy-text">
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately, after
                they are posted on this page.
              </p>

              <h3>Contact Us</h3>

              <p className="privacy-policy-text">
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to{" "}
                <a
                  onClick={() => {
                    RiLinkUtils.contactRek();
                  }}
                >
                  contact us
                </a>
                .
              </p>
            </>
          );
        //#endregion

        //#region REACH OUT
        case RiSlideType.ReachOut:
          return (
            <>
              {/* HEADLINE - DOWNLOAD READY */}
              <h2>
                <strong>👋&nbsp;&nbsp;React Out</strong>
              </h2>

              {/* PARAGRAPH - DOWNLOAD READY */}
              <p className="download-paragraph">
                <span className="brand-fg">Questions</span>?{" "}
                <span className="brand-fg">Ideas</span>? Philosophical rants
                about the state of humanity? We're all ears.
              </p>

              <p className="download-paragraph" style={{ paddingTop: 12 }}>
                Shoot us an email @{" "}
                <span className="brand-fg">hello@rekit.app</span>!
              </p>

              {/* REACH OUT BUTTONS */}
              <div className="download-multi-button-container">
                <RiBlurButton
                  icon={<RiCopy className="path-fill-white" />}
                  text={
                    store.RiSlideoutStore.isLinkCopied
                      ? RiStringUtils.iconLinkCopied
                      : RiStringUtils.iconLinkCopy
                  }
                  handler={async () => {
                    await navigator.clipboard.writeText("hello@rekit.app");
                    store.RiSlideoutStore.setLinkCopied();
                  }}
                />
                <RiBlurButton
                  icon={<RiEmail className="path-fill-white" />}
                  text={RiStringUtils.iconLinkOpen}
                  handler={() => {
                    RiLinkUtils.launchEmail();
                  }}
                />
              </div>
            </>
          );
        //#endregion
      }
    };

    return (
      <div
        className={`download-container ${
          !isMount && "download-container-close-ani"
        }`}
      >
        {/* CLOSE X */}
        <div className="close-x" onClick={handeClose}>
          <RiClose className="path-stroke-white" />
        </div>

        {/* SCROLLABLE CONTENT */}
        <div
          className={`slide-scrollable ${
            props.type === RiSlideType.ReachOut ||
            (props.type === RiSlideType.DownloadLinks &&
              "slide-scrollable-center")
          }`}
        >
          {getRender()}
        </div>

        {/* CLOSE BUTTON */}
        <div className="close-button" onClick={handeClose}>
          <RiBlurButton text="Close" handler={() => {}} />
        </div>
      </div>
    );
  }
);
