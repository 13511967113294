import { RiLinkTag } from "../../Components/RiLinkTag/RiLinkTag";
import { RiLinkUtils } from "../../Utils/RiLinkUtils";
import { RiStringUtils } from "../../Utils/RiStringUtils";
import "./RiFooter.scss";

export interface IRiFooterProps {
  handleClickDownload: () => void;
  handleClickPrivacy: () => void;
}

export const RiFooter = (props: IRiFooterProps): JSX.Element => {
  return (
    <div className="footer-container">
      {/* FOOTER HEADLINE */}
      <h1 className="thank-you-footer">
        <strong>❤️ Thank you</strong>
      </h1>

      {/* COPYRIGHT */}
      <div className="footer-links">
        <span className="grey-text footer-copyright">
          Copyright ©2021 Rek it.
        </span>
        <span className="grey-text footer-copyright">
          <a onClick={props.handleClickPrivacy}>Privacy Policy</a>
        </span>
      </div>
    </div>
  );
};
