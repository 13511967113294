import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';
import { RiRekLogo } from '../Icons/RiRekLogo/RiRekLogo';
import './RiPageLoader.scss';

export interface IRiPageLoaderProps {
    isFinishedLoading: boolean;
}

export const RiPageLoader = observer(() => {
    {
        const [isAnimation, setIsAnimation] = useState<boolean>(true);
        const [isPageHidden, setIsPageHidden] = useState<boolean>(false);
        const logoSize: number = 44;
        const store: RiRootStore = riRootStoreInstance;
        const checkIfLoaded = (): void => {
            if (store.RiLoadingStore.isPageLoaded) {
                setIsAnimation(false);
                setTimeout(() => {
                    setIsPageHidden(true);
                    store.RiLoadingStore.updateStateAsync({ isAniFirstPaint: true });
                }, 500);
            } else return;
        }
        return (
            <div className={`${isPageHidden && 'page-loader-container-hide'} page-loader-container`}>

                {/* BG PULSING */}
                <div className={`${isAnimation && 'page-loader-pulse-ani'} page-loader-pulse`} />

                {/* ICON  */}
                <div className={`${isAnimation ? 'page-loader-icon' : 'ani-scale-down'}`} onAnimationIteration={checkIfLoaded}>
                    <RiRekLogo className='path-fill-brand' style={{ height: logoSize, width: logoSize }} />
                </div>

            </div>
        )
    }
});