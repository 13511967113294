import ssWitcher from '../../Assets/Screenshots/Feb2022/witcherFeed.png';
import { RiComment } from '../../Components/Icons/RiComment/RiComment';
import { RiPeople } from '../../Components/Icons/RiPeople/RiPeople';
import { RiThumbUp } from '../../Components/Icons/RiThumbUp/RiThumbUp';
import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';
import './RiWhy.scss';

export const RiWhy = (): JSX.Element => {
    const store: RiRootStore = riRootStoreInstance;
    return (
        <div className="main-view-container why-bottom-spacing">
            {/* LEFT SIDE */}
            <div className='column-container'>
                {/* HEADLINE */}
                {/* <h1><strong>Why?</strong></h1> */}

                {/* TAGLINE */}
                {/* <p className='tagline-text'>We noticed how often our <span className='brand-fg'>friends would recommend</span> stuff to us.</p> */}
                <p className='tagline-text why-header-ani'>See what your <span className='brand-fg'>friends</span> are recommending in your feed.</p>
                {/* <p className='tagline-text'>Yet there was <span className='brand-fg'>no app</span> to keep track of it all. So we built one. 🤙</p> */}

                {/* FEED ACTION ICONS */}
                <div className='why-action-icons'>

                    {/* PEOPLE */}
                    <RiPeople className='path-fill-grey' />
                    <span className='rek-icon-text'>{293}</span>

                    {/* THUMB UP */}
                    <RiThumbUp className='path-fill-grey' />
                    <span className='rek-icon-text'>{180}</span>

                    {/* COMMENTS */}
                    <RiComment className='path-fill-grey' />
                    <span className='rek-icon-text'>{40}</span>


                </div>
            </div>

            {/* APP IMAGE */}
            <div className='why-screenshot'>
                <img
                    src={ssWitcher}
                    className='why-screenshot-image'
                    onLoad={() => store.RiLoadingStore.imgLoaded()}
                />
            </div>

        </div>
    )
}