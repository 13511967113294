import { IRiRekRowProps } from "../../Components/RiRekRow/RiRekRow";
// Favicons
import netflixFavicon from '../../Assets/LinkFavicons/netflix.png';
import audibleFavicon from '../../Assets/LinkFavicons/audible.png';
import spotifyFavicon from '../../Assets/LinkFavicons/spotify.png';
import huluFavicon from '../../Assets/LinkFavicons/hulu.png';
import chipotleFavicon from '../../Assets/LinkFavicons/chipotle.png';
// Link Images
import darkImage from '../../Assets/LinkImages/Dark.jpeg';
import braveImage from '../../Assets/LinkImages/braveNewWorld.jpg';
import sharonImage from '../../Assets/LinkImages/sharonJones.jpeg';
import devsImage from '../../Assets/LinkImages/devs.jpeg';
import chipotleImage from '../../Assets/LinkImages/chipotle.jpg';

export const rekExamples: IRiRekRowProps[] = [
    // DARK
    {
        title: 'Watch Dark | Netflix Official Site',
        description: 'A missing child sets four families on a frantic hunt for answers as they unearth a mind-bending mystery that spans three generations.',
        category: 'TV Shows',
        image: darkImage,
        favicon: netflixFavicon,
        reks: 194,
        likes: 160,
        comments: 28,
    },
    // BRAVE NEW WORLD
    {
        title: 'Brave New World',
        description: 'Check out this great listen on Audible.com. When Lenina and Bernard visit a savage reservation, we experience how Utopia can destroy humanity. On the 75th anniversary of its publication, this outstanding work of literature is more crucial and relevant today than ever before. Cloning, feel-good dru',
        category: 'Books',
        image: braveImage,
        favicon: audibleFavicon,
        reks: 240,
        likes: 200,
        comments: 42,
    },
    // MUSIC
    {
        title: 'Sharon Jones & The Dap-Kings',
        description: 'Artist · 2.3M monthly listeners.',
        category: 'Music',
        image: sharonImage,
        favicon: spotifyFavicon,
        reks: 89,
        likes: 68,
        comments: 12,
    },
    // DEVS
    // {
    //     title: 'Devs',
    //     description: 'In Devs, an FX limited series, a young software engineer, Lily Chan, investigates the secret development division of her employer, a cutting-edge tech company based in Silicon Valley, which she believes is behind the murder of her boyfriend.',
    //     category: 'TV Show',
    //     image: devsImage,
    //     favicon: huluFavicon,
    //     reks: 48,
    //     likes: 37,
    //     comments: 44,
    // },
    // FOOD
    {
        title: 'Mexican Food - Restaurant & Catering - Chipotle Mexican Grill',
        description: 'Order tacos, burritos, salads, bowls and more at Chipotle Mexican Grill. Order online for pick up or delivery and join our rewards program today.',
        category: 'Food',
        image: chipotleImage,
        favicon: chipotleFavicon,
        reks: 761,
        likes: 603,
        comments: 91,
    },
]