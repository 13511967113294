import './RiAboveFold.scss';
import ssCobra from '../../Assets/Screenshots/Feb2022/cobraFeed.png';
import riRootStoreInstance, { RiRootStore } from '../../Store/RiRootStore';
import { observer } from 'mobx-react-lite';

export const RiAboveFold = observer(() => {
    const store: RiRootStore = riRootStoreInstance;
    return (
        <div className="main-view-container above-fold-bottom-spacing">
            {/* HEADLINE */}
            <div className={`column-container main-headline ${store.RiLoadingStore.isAniFirstPaint && 'main-headline-ani'}`}>
                <h1>Keep track of your <strong>recommendations</strong>.</h1>
            </div>

            {/* SCREENSHOT */}
            <div className={`above-fold-screenshot`}>
                <img
                    src={ssCobra}
                    className={`${store.RiLoadingStore.isAniFirstPaint && 'above-fold-screenshot-image-ani'}  above-fold-screenshot-image`}
                    onLoad={() => store.RiLoadingStore.imgLoaded()}
                />
            </div>
        </div>
    );
}) 
